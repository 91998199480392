import React, { createContext, useContext, useEffect, useState } from 'react';
import { filterData } from '../Utils/Constants';

// Create the context
const FiltersContext = createContext();

// Hook to access the FiltersContext
export const useFilters = () => useContext(FiltersContext);

// Provider component to wrap the app
export const FiltersProvider = ({ children }) => {
    const [selectedFilters, setSelectedFilters] = useState({});
    const [chipColors, setChipColors] = useState({});

    // Load filters from localStorage
    const loadFilters = async () => {
        try {
            const savedFilters = await localStorage.getItem('selectedFilters');
            if (savedFilters) {
                setSelectedFilters(JSON.parse(savedFilters));
            }
        } catch (error) {
            console.error('Failed to load filters from storage', error);
        }
    };

    // Save filters to localStorage
    const saveFilters = async () => {
        try {
            if (Object.keys(selectedFilters).length > 0) {
                await localStorage.setItem('selectedFilters', JSON.stringify(selectedFilters));
            } else {
                await localStorage.removeItem('selectedFilters');
            }
        } catch (error) {
            console.error('Failed to save filters to storage', error);
        }
    };

    // Clear all filters
    const clearFilters = async () => {
        try {
            await localStorage.removeItem('selectedFilters');
            setSelectedFilters({});
            setChipColors({});
            loadFilters()
        } catch (error) {
            console.error('Failed to clear filters from storage', error);
        }
    };

    // Remove a specific filter
    const handleRemoveFilter = async (category, id, product = null) => {
        const compositeKey = `${category}_${id}`;
        const updatedFilters = { ...selectedFilters };
        delete updatedFilters[compositeKey];
        try {
            await localStorage.setItem('selectedFilters', JSON.stringify(updatedFilters));
            setSelectedFilters(updatedFilters);
            if (product) {
                checkConflicts(product)
            }
        } catch (error) {
            console.error('Failed to save filters to storage', error);
        }
    };

    // Get the label for a specific filter by its category and ID
    const getFilterLabelById = (category, id) => {
        const found = filterData[category]?.find(filter => filter.id === id);
        if (found) {
            switch (category) {
                case 'diets':
                    return found.diet;
                case 'allergies':
                    return found.allergy;
                case 'nutrients':
                    return found.nutrient;
                case 'sustainability':
                    return found.sustainability;
                case 'ingredients':
                    return found.ingredient;
                case 'values':
                    return found.value;
                default:
                    return '';
            }
        }
        return '';
    };

    // Check if a product has conflicts with selected filters
    const checkConflicts = async (product) => {
        if (!product) {
            // If no product, set all selected filters to default color #fff
            const defaultColors = {};
            selectedFilters && Object.keys(selectedFilters).length > 0 && Object.keys(selectedFilters).forEach((compositeKey) => {
                defaultColors[compositeKey] = '#fff'; // Set default color
            });
            setChipColors(defaultColors);
            return;
        }
        const preferences = await localStorage.getItem('selectedFilters');
        if (preferences) {
            const selectedPreferences = JSON.parse(preferences);

            const productDietConflicts = product?.dietConflicts?.split(',').map(item => item.trim().toLowerCase()) || [];
            const productAllergyConflicts = product?.allergyConflicts?.split(',').map(item => item.trim().toLowerCase()) || [];
            const productIngredientConflicts = product?.ingredientConflicts?.split(',').map(item => item.trim().toLowerCase()) || [];
            let productPositiveIngredients = [];
            try {
                productPositiveIngredients = product?.positiveIngredients && JSON.parse(product?.positiveIngredients || '[]').map(item => item.trim().toLowerCase());
            } catch (error) {
                console.error('Failed to parse positiveIngredients:', error);
            }
            const productValues = product?.values?.map(item => item.trim().toLowerCase()) || [];
            const productSustainabilityValues = product?.sustainabilityTags?.split(',').map(item => item.trim().toLowerCase()) || [];
            const productNutrients = product?.nutritionTags?.split(',').map(item => item.trim().toLowerCase()) || [];

            const dietConflicts = [];
            const allergyConflicts = [];
            const sustainabilityConflicts = [];
            const ingredientConflicts = [];
            const valueConflicts = [];
            const nutrientConflicts = [];

            Object.keys(selectedPreferences).forEach((compositeKey) => {
                const [category, id] = compositeKey.split('_');
                const filterLabel = getFilterLabelById(category, parseInt(id)).toLowerCase();

                if (category === 'diets' && (productDietConflicts.includes(filterLabel) || (filterLabel === 'no added sugar' && productIngredientConflicts.includes('added sugar')))) {
                    dietConflicts.push(compositeKey);
                }
                if (category === 'allergies') {
                    const strippedLabel = filterLabel.replace(/^no\s+/, '');
                    if (productAllergyConflicts.includes(strippedLabel)) {
                        allergyConflicts.push(compositeKey);
                    }
                }
                if (category === 'sustainability') {
                    let isConflict = false;

                    if (filterLabel === "low carbon footprint") {
                        const containsCarbonMatch = productSustainabilityValues.some(item =>
                            item.toLowerCase().includes('low carbon footprint')
                        );
                        isConflict = !containsCarbonMatch;
                    } else if (filterLabel === "low water footprint") {
                        const containsWaterMatch = productSustainabilityValues.some(item =>
                            item.toLowerCase().includes('low water footprint')
                        );
                        isConflict = !containsWaterMatch;
                    } else if (filterLabel === "usda 100% organic") {
                        const containsUSDAHunderdOrganicMatch = productSustainabilityValues.some(item =>
                            item.toLowerCase() === 'usda 100% organic claim derived'
                        );
                        isConflict = !containsUSDAHunderdOrganicMatch;
                    } else if (filterLabel === "usda organic") {
                        const containsUSDAOrganicMatch = productSustainabilityValues.some(item =>
                            item.toLowerCase() === 'usda organic claim derived'
                        );
                        isConflict = !containsUSDAOrganicMatch;
                    } else if (filterLabel === "usda made with organic") {
                        const containsUSDAMadeWithOrganicMatch = productSustainabilityValues.some(item =>
                            item.toLowerCase() === 'usda made with organic claim derived'
                        );
                        isConflict = !containsUSDAMadeWithOrganicMatch;
                    } else if (filterLabel === "usda contains organic ingredients") {
                        const containsUSDAOrganicIngredientMatch = productSustainabilityValues.some(item =>
                            item.toLowerCase() === 'usda contains organic ingredients claim derived'
                        );
                        isConflict = !containsUSDAOrganicIngredientMatch;
                    } else {
                        isConflict = !productSustainabilityValues.includes(filterLabel);
                    }
                    if (isConflict) {
                        sustainabilityConflicts.push(compositeKey);
                    }
                }
                if (category === 'ingredients') {
                    const strippedLabel = filterLabel.replace(/^no\s+/, '');
                    const isConflict = productIngredientConflicts.includes(strippedLabel);
                    const isSpecificPositive = filterLabel === '100% whole grain' || filterLabel === 'contains whole grain';
                    const isPositive = productPositiveIngredients.includes(filterLabel);

                    if (isConflict) {
                        ingredientConflicts.push(compositeKey);
                    } else if (isSpecificPositive && (!isPositive || productPositiveIngredients.length === 0)) {
                        ingredientConflicts.push(compositeKey);
                    }
                }
                if (category === 'values' && !productValues.includes(filterLabel)) {
                    valueConflicts.push(compositeKey);
                }
                if (category === 'nutrients' && !productNutrients.includes(filterLabel)) {
                    nutrientConflicts.push(compositeKey);
                }
            });

            const updatedChipColors = {};
            Object.keys(selectedPreferences).forEach((compositeKey) => {
                if (dietConflicts.includes(compositeKey) || allergyConflicts.includes(compositeKey) || valueConflicts.includes(compositeKey) || ingredientConflicts.includes(compositeKey) || sustainabilityConflicts.includes(compositeKey) || nutrientConflicts.includes(compositeKey)) {
                    updatedChipColors[compositeKey] = '#FCDDDD';
                } else {
                    updatedChipColors[compositeKey] = '#EEE';
                }
            });
            setChipColors(updatedChipColors);
        }
    };

    // Toggle filter on/off
    const toggleSwitch = async (category, id) => {
        const compositeKey = `${category.toLowerCase()}_${id}`;
        setSelectedFilters((prev) => {
            const updatedFilters = { ...prev };
            if (updatedFilters[compositeKey]) {
                delete updatedFilters[compositeKey];
            } else {
                updatedFilters[compositeKey] = true;
            }
            return updatedFilters;
        });
    };

    useEffect(() => {
        saveFilters()
    }, [selectedFilters])

    return (
        <FiltersContext.Provider
            value={{
                loadFilters,
                checkConflicts,
                saveFilters,
                selectedFilters,
                chipColors,
                getFilterLabelById,
                handleRemoveFilter,
                toggleSwitch,
                clearFilters,
            }}
        >
            {children}
        </FiltersContext.Provider>
    );
};
